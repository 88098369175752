@import "../../../../Assets/styles/index.scss";

.heroSectionCustomer {
    --fsSubtit: 15.5px;
    --fsTit: 48px;
    --fsDetail: 18px;
    --paddingDetail: 80px;
    --whiteSpace: pre-line;
    --lhDetail: 1.4;
    --leftContainer: 240px;
    --ptContent: 150px;
    --paddingContactItem: 20px 25px 20px 35px;
    --sizeImg: 100px;
    --fsSubButton: 16px;
    --fsTitButton: 40.5px;
    --fsDetailButton: 24px;
    --width: 1140px;
    --height: 881px;
    --grid: 1fr 1fr;
    --imgWidth: 424px;
    --imgHeight: 363px;

    .section {
        height: var(--height);
        width: 100%;
        position: relative;
        background-color: #162a56;

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            width: var(--width);
            &-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .content {
                    flex: 1;
                    .subtit {
                        font-size: var(--fsSubtit);
                        color: #0062f4;
                        font-family: $pretendardSemiBold;
                    }
        
                    .title {
                        padding: 30px 0;
                        font-size: var(--fsTit);
                        font-family: $pretendardSemiBold;
                        color: $white-cl;
                        white-space: pre-line;
        
                        p {
                            line-height: 1.4;
                        }
                    }
        
                    .detail {
                        font-size: var(--fsDetail);
                        color: #eef6ff;
                        padding-bottom: var(--paddingDetail);
                        white-space: var(--whiteSpace);
        
                        p {
                            line-height: var(--lhDetail);
        
                            @media screen and (max-width: 767px) {
                                white-space: normal;
                                line-height: 1.4;
                            }
                        }
                    }
        
                    .button {
                        display: inline-block;
                    }
                }
                .img {
                    padding-left: 30px;
                    img {
                        width: var(--imgWidth);
                        height: var(--imgHeight);
                        object-fit: contain;
                    }
                }
            }
            
            

            .contact {

                display: grid;
                grid-template-columns: var(--grid);
                column-gap: 24px;
                row-gap: 30px;
                padding-top: var(--ptContent);

                &-item {
                    display: flex;
                    align-items: center;
                    padding: var(--paddingContactItem);
                    background-color: #fff;
                    border-radius: 16px;

                    &-img {
                        width: var(--sizeImg);
                        height: var(--sizeImg);

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    &-content {
                        flex: 1;
                        padding-left: 23px;
                        display: flex;
                        flex-direction: column;

                        .subTit {
                            font-size: var(--fsSubButton);
                            color: #0062f4;
                            padding-bottom: 10px;
                        }

                        .title {
                            font-size: var(--fsTitButton);
                            color: #222;
                            padding: 0;
                        }

                        .content-detail {
                            font-size: var(--fsDetailButton);
                            font-family: $pretendardSemiBold;
                            padding-top: 5px;
                            white-space: nowrap;

                            span {
                                color: #999;
                                padding-right: 12px;
                            }
                        }
                    }

                    &-note {
                        font-size: var(--fsSubButton);
                        color: #999;
                        align-self: flex-start;
                    }
                }
            }

        }

    }

    @media screen and (max-width: 1450px) {
        --width: 1200px;
    }

    @media screen and (max-width: 1280px) {
        --grid: 1fr;
        --width: 800px;
        --height: 1101px;
    }

    @media screen and (max-width: 900px) {
        --fsSubtit: 13.5px;
        --fsTit: 28px;
        --fsDetail: 18px;
        --paddingDetail: 60px;
        --whiteSpace: normal;
        --lhDetail: 1.4;
        --leftContainer: 40px;
        --ptContent: 100px;
        --paddingContactItem: 20px 25px 20px 35px;
        --sizeImg: 80px;
        --fsSubButton: 16px;
        --fsTitButton: 30.5px;
        --fsDetailButton: 20px;
        --width: 90%;
    }

    @media screen and (max-width: 767px) {
        --fsSubtit: 13.5px;
        --fsTit: 28px;
        --fsDetail: 18px;
        --paddingDetail: 60px;
        --whiteSpace: normal;
        --lhDetail: 1.4;
        --leftContainer: 40px;
        --ptContent: 40px;
        --paddingContactItem: 20px 25px 20px 15px;
        --sizeImg: 50px;
        --fsSubButton: 10px;
        --fsTitButton: 15.5px;
        --fsDetailButton: 14px;
        --width: 90%;
        .section-container .contact-item {
            height: 90px;
        }
        .section-container-content {
            flex-direction: column;
        }
    }
}