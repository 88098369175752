@import "../../../../Assets//styles/index.scss";

.marketingService {
    --padding: 140px 25px;
    --ptList: 80px;
    --fsTit: 24px;
    --fsDetail: 16px;
    --mtList: 90px;
    --paddingItem: 30px;
    --sizeImg: 72px;
    --grid: 1fr 1fr 1fr 1fr;
    --rowGap: 0;
    background-color: #cfe2fd;

    &-container {
        margin: 0 auto;
        max-width: $maxWidth;
        padding: var(--padding);

        &-list {
            display: grid;
            grid-template-columns: var(--grid);
            margin-top: var(--mtList);
            column-gap: 24px;
            row-gap: var(--rowGap);

            .item {
                background-color: #fff;
                border-radius: 17px;
                padding: var(--paddingItem);

                img {
                    width: var(--sizeImg);
                    height: var(--sizeImg);
                    object-fit: contain;
                }

                .title4 {
                    font-size: var(--fsTit);
                    font-family: $pretendardSemiBold;
                    padding: 15px 0;
                    font-size: 18px;
                    text-align: center;
                }

                .detail4 {
                    white-space: pre-line;
                    text-align: center;
                    font-size: var(--fsDetail);
                    line-height: 1.6;
                    color: #999;
                }
            }
        }
    }

    @media screen and (max-width: 1300px) {
        --grid: 1fr 1fr;
        --rowGap: 30px;
    }

    @media screen and (max-width: 767px) {
        --grid: 1fr;
        --padding: 40px 20px;
        --ptList: 40px;
        --fsTit: 20px;
        --fsDetail: 14px;
        --paddingItem: 15px;
    }
}