@import "../../../Assets/styles/variables";

footer {
    --widthLogo: 130px;
    --heightLogo: 42px;
    margin: 0 auto;
    max-width: $maxWidth;
    padding: 64px 25px 79px;
    display: flex;
    align-items: flex-start;

    .leftItem {
        flex: 1;

        .logo {
            width: var(--widthLogo);
            height: var(--heightLogo);
            margin-bottom: 24px;
            cursor: pointer;
            display: inline-block;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .phone,
        .address,
        .contact,
        .mail {
            display: flex;
            align-items: center;
            padding-top: 12px;
        }

        .contact-item {
            display: flex;
            align-items: center;
            font-size: 16.5px;
            color: #999;

            @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 1.5;
            }


        }
        .contact-item2{
            font-weight: 600;
            font-size: 17.5px;
            color: #333;
        }


        .itemRight {
            padding-left: 12px;
        }

        .private {
            margin-top: 40px;
            margin-bottom: 20px;

            a {
                font-family: $pretendardSemiBold;
                color: #333;
                font-size: 16.5px;
            }

        }

        p {
            color: #999;
            font-size: 16.5px;
        }
    }

    .rightItem {
        display: flex;
        align-items: flex-start;
        .cooperate {
            display: flex;
            justify-content: center;
            align-items: center;
            p {
                font-size: 16.5px;
                font-family: $pretendardSemiBold;
            }
            &-img {
                width: 120px;
                height: 67px;
                padding: 0 20px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    cursor: pointer;
                }
            }
        }
        .items {
            display: flex;
            align-items: center;
        }
        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-left: 30px;
            cursor: pointer;
            transition: 400ms;

            &:hover {
                transform: scale(1.1);
            }

            .item-img {
                background-color: #162a56;
                padding: 10px 10px;
                border-radius: 99px;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 18px;
                    height: 18px;
                    object-fit: cover;
                }
            }

            .item-detail {
                text-align: center;
                padding-top: 15px;
                font-family: $pretendardSemiBold;
            }
        }

    }

    @media screen and (max-width: 1164px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .rightItem {
            padding-top: 40px;
        }
    }

    @media screen and (max-width: 975px) {
        padding: 64px 20px 79px;
    }

    @media screen and (max-width: 767px) {
        --widthLogo: 90px;
        --heightLogo: 28px;
        padding: 34px 20px 39px;
        align-items: flex-start;

        .leftItem {
            width: 100%;
            justify-content: center;
            text-align: center;

            .detail-contact {
                &-item {
                    margin-left: 0 !important;
                    width: 100%;

                }
            }

            .phone,
            .address,
            .contact,
            .mail {
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }
        }

        .rightItem {
            justify-content: center;
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            padding-top: 10px;
            .item {
                padding-top: 15px;
                padding-left: 14px;
                margin-left: 0;
            }
            .cooperate {
                p {
                    font-size: 12px;
                }
            }

            .item-detail {
                font-size: 12px;
            }
        }
    }


}