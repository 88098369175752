@import "../../../Assets/styles/index.scss";
#domian-login {
  .domian_login_contents {
    width: 460px;
    background-color: #fff;
    padding: 40px;
    border-radius: 12px;
    .choice_logo {
      height: 40px;
    }

    .choice {
      width: 180px;
      height: 180px;
    }
  }

  .text-btn {
    all: unset;
    cursor: pointer;
    font-size: 14px;
    color: $primary;

    &:hover {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
