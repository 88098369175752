#star {
  background-color: #f2f3f8;
  .con {
    max-width: 768px;
    margin: 0 auto;
    background-color: white;
    height: 100vh;
    padding: 48px 40px;
    .logo {
      margin: 0 auto;
    }

    .text-center {
      text-align: center;

      .sub_title {
        font-size: 18px;
        font-weight: bold;
        margin: 0 auto;
      }

      .textarea {
        width: 100%;
        padding: 8px;
        border-radius: 10px;
        resize: none;
        border: 2px solid #e5e7eb;
        height: 300px;
        color: black;
        font-weight: 700;
      }
    }
  }
}
