@import "../../../../Assets/styles/index.scss";

.platform-intro {
    --padding: 140px 25px;
    --mtImg: 80px;
    margin: 0 auto;
    max-width: $maxWidth;
    padding: var(--padding);

    &-img {
        margin-top: var(--mtImg);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &-img-mobile {
        display: none;
    }

    @media screen and (max-width: 767px) {
        --padding: 40px 20px;
        --mtImg: 60px;

        &-img {
            display: none;
        }

        &-img-mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: var(--mtImg);

            .topItems {
                background-color: #f1f8ff;
                padding: 20px;
                padding-bottom: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                border-radius: 40px;

                .topItem {
                    margin-top: 10px;
                    background-color: #f9f9f9;
                    border: 1px solid #f9f9f900;
                    padding: 20px;
                    border-radius: 38px;

                    &:not(:first-child) {
                        margin-top: 30px;
                    }

                    &-tit {
                        font-size: 18px;
                        font-family: $pretendardSemiBold;
                        padding-top: 8px;
                    }

                    &-detail {
                        font-size: 16px;
                        padding-top: 9px;
                        line-height: 1.5;
                    }
                }
            }

            .divide {
                height: 46px;
                background-color: #162a56;
                width: 1px;
                margin: 12px 0;
            }

            .middleItem {
                width: 180px;
                height: 180px;
                border-radius: 999px;
                background-color: #162a56;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    color: #fff;
                    font-size: 24px;
                    font-family: $pretendardSemiBold;
                }
            }

            .bottomItems {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #f1f8ff;
                padding: 12px;
                border-radius: 30px;
                width: 80%;
                padding-bottom: 20px;

                .bottomItem {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 280px;
                    height: 200px;
                    border-radius: 50px;
                    color: #fff;
                    background-color: #0062f4;
                    margin-top: 20px;
                    text-align: center;

                    .icon {
                        width: 25px;
                        height: 25px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .text {
                        font-size: 16px;
                        font-family: $pretendardSemiBold;
                        padding: 12px 10px 0 ;
                        line-height: 1.4;
                    }
                }
            }
        }
    }
}