@import "../../../../Assets/styles/index.scss";

.platformMap {
    --height: 1316px;
    --heightImg: 709px;
    --sizeImgItem: 100px;
    --fsTit: 24px;
    --fsDetail: 16px;
    --plImg: 23px;
    --grid: 1fr 1fr 1fr;
    position: relative;
    height: var(--height);

    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #cfe2fd;
    }

    &-container {
        position: absolute;
        max-width: $maxWidth;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 100%;
        padding: 0 20px;


        .map {
            margin: 50px 0;

            img {
                width: 100%;
                max-height: var(--heightImg);
                object-fit: contain;
            }
        }

        .map-list {
            display: grid;
            grid-template-columns: var(--grid);

            .item {
                display: flex;
                align-items: center;

                .img {
                    img {
                        width: var(--sizeImgItem);
                        height: var(--sizeImgItem);
                        object-fit: contain;
                    }
                }

                .content2 {
                    padding-left: var(--plImg);
                    text-align: center;
                    .title {
                        font-size:20px;
                        font-family: $pretendardSemiBold;
                        white-space: pre-line;
                    }

                    .detail {
                        font-size: 14.5px;
                        padding-top: 10px;
                        white-space: pre-line;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1500px) {
        // --maxWidth: 90%;
    }

    @media screen and (max-width: 1200px) {
        --sizeImgItem: 70px;
        --fsTit: 22px;
        --fsDetail: 15px;
        --plImg: 15px;
        --height: 1016px;
    }

    @media screen and (max-width: 767px) {
        --sizeImgItem: 50px;
        --fsTit: 20px;
        --fsDetail: 15px;
        --plImg: 0px;
        --grid: 1fr;
        --height: 900px;

        .map-list {
            .item {
                justify-content: center;
                padding-top: 20px;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }
    }
}