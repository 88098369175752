@import "../../../../Assets/styles/index.scss";
.partner {
    background-color: #f1f8ff;
    &-container {
        margin: 0 auto;
        max-width: $maxWidth;
        padding: 140px 25px;
        .partner-list {
            padding-top: 81px;  
            visibility: hidden;   
        }
        @media screen and (max-width: 768px) {
            padding: 60px 20px;
            .partner-list {
                visibility: visible;   
            }
        }
    }

}