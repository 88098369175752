@import "../../../../Assets//styles/index.scss";

.mornitoringService {
    --padding: 140px 25px;
    --ptList: 80px;
    --fsTit: 24px;
    --fsDetail: 16px;
    --mtList: 90px;
    --paddingItem: 30px;
    --sizeImg: 82px;
    --ptButton: 100px;
    --grid: 1fr 1fr;
    background-color: #f1f8ff;

    &-container {
        margin: 0 auto;
        max-width: $maxWidth;
        padding: var(--padding);

        &-list {
            display: grid;
            grid-template-columns: var(--grid);
            margin-top: var(--mtList);
            column-gap: 24px;
            row-gap: 24px;

            .item {
                background-color: #fff;
                border-radius: 17px;
                padding: var(--paddingItem);
                display: flex;
                align-items: center;

                img {
                    width: var(--sizeImg);
                    height: var(--sizeImg);
                    object-fit: contain;
                }

                .content {
                    padding-left: var(--paddingItem);

                    .title {
                        font-size: var(--fsTit);
                        font-family: $pretendardSemiBold;
                        padding: 18px 0 0;
                    }

                    .detail {
                        white-space: pre-line;
                        font-size: var(--fsDetail);
                        line-height: 1.4;
                        color: #999;
                    }
                }

            }
        }

        &-button {
            margin-top: var(--ptButton);
            display: flex;
            justify-content: center;
        }
    }

    @media screen and (max-width: 1200px) {
        --grid: 1fr;

    }

    @media screen and (max-width: 1200px) {
        --padding: 40px 20px;
        --ptList: 40px;
        --fsTit: 20px;
        --fsDetail: 14px;
        --mtList: 50px;
        --paddingItem: 20px;
        --sizeImg: 82px;
        --ptButton: 50px;
    }
}