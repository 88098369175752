@import "../../../Assets/styles/index.scss";

.header {
  --spaceLogin: 0 48px;
  --widthLogo: 130px;
  --heightLogo: 42px;
  top: 0px;
  right: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 80px;
  position: fixed;
  box-shadow: $box-shadow;
  @include fadein();

  a {
    color: $clText-header;
    font-size: 16px;
    transition: 400ms;

    &:hover {
      color: $white-cl;
    }
  }
  .ant-select-selector{
    border-radius: 50px;
    display: flex;
    align-items: center;
  }
  .lang{
    .ant-select {
      width:100px;
    }
  }
  &_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    .header_title {
      width: var(--widthLogo);
      height: var(--heightLogo);
      font-weight: bold;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .list_menu {
      display: flex;
      margin-bottom: 0;
      padding-left: 25px;

      .menu_items {
        padding-left: 46px;

        .activeItemsPage {
          color: $white-cl;
        }

      }
    }
  }

  .header_login {
    display: flex;
    align-items: center;
    .lang{
      margin-right: 10px;
    }
    .login {
      margin: 0 40px 0 0;
    }

  }

  .header_mobile_icon {
    display: none;
    
  }

  @media screen and (max-width: 1200px) {
    --spaceLogin: 20px;
    --widthLogo: 84px;
    --heightLogo: 28px;
    padding: 25px 40px;

    &_menu {
      .list_menu {
        .menu_items {
          padding-left: 20px;

          a {
            font-size: 13px;
          }
          
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    padding: 20px 20px;

    &_menu {
      .list_menu {
        display: none;
      }

    }

    .header_login {
      display: none;
    }

    .header_mobile_icon {
      display: block;
      width: 30px;
      height: 30px;
      color: $white-cl;

      
    }
    

  }
}


.header-mobile {
  position: fixed;
  background-color: #222;
  top: 0;
  right: 0;
  width: 350px;
  height: 100vh;
  z-index: 99;
  transform: translateX(100%);
  transition: 500ms;

  .icon-close {
    display: flex;
    padding: 20px 20px;
    justify-content: flex-end;

    span {
      color: $white-cl;
      font-size: 30px;
    }
  }

  .menu-mobile-items {
    .list_menu {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      .menu_items {
        padding-left: 46px;
        padding: 25px 0;
        text-align: center;

        a {
          color: $white-cl;
          font-size: 20px;
        }

      }
    }
  }

  .menu-mobile-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

      .ant-select-selector{
        border-radius: 50px;
        display: flex;
        align-items: center;
      }

    .lang{
      margin-top: 10px;
      width:134px
    }
    .ant-select {
      width:134px;
      display:flex;
      text-align: center;
align-items: center;
    }
    a {
      color: $white-cl;
      font-size: 20px;
      margin-bottom: 30px;
    }

    .login {
      margin-top: 20px;
      // margin-bottom: 50px;
    }
  }
}

.openMenu {
  transform: translateX(0);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border: none;
}

span.ant-select-selection-item {
  color: #000;
  font-size: 16px;
  font-family: $pretendardSemiBold;
  line-height: 39px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 42px;
  padding: 0 16px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 41px;
  transition: all 0.3s, visibility 0s;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  color: #000;
}


.ant-select .ant-select-arrow {
  display: flex;
  align-items: center;
  color: #000;
}

.ant-select-dropdown {
  color: $clText-header;
  font-family: $pretendardSemiBold;
  background-color: #fff;
}

.ant-select-item-option-content {

  color: #000;
  font-size: 16px;
  font-family: $pretendardSemiBold;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #000;
  font-weight: 600;
  font-family: $pretendardSemiBold;
  background-color: #c7c0c7;
}