@import "../../../../Assets/styles/index.scss";

.hero-section {
    --fsSubtit: 15.5px;
    --fsTit: 48px;
    --fsDetail: 18px;
    --paddingDetail: 80px;
    --whiteSpace: pre-line;
    --lhDetail: 1.4;
    --height: 788px;
    --heightHeader: 92px;
    background-color: #000;
    margin-top: var(--heightHeader);

    .overlay {
        width: 100%;
        height: 100%;
        background-color: transparent;
        // position: relative;
        top: 0;
        left: 0;
        z-index: 3399;
        transition: 1000ms ease-in-out;

        // @media screen and (max-width: 850px) {
        //     display: none;
        // }

    }

    .iframe {
        width: 100%;
        height: var(--height);
        position: relative;

        &-video {
            height: 100%;
            width: 100%;
        }
    }


    @media screen and (max-width: 850px) {
        --height: 600px;
        --heightHeader: 70px;

        .section {
            padding-bottom: 50px;
            align-items: flex-end;
        }

    }
}