@import "../../../Assets/styles/index.scss";

.banner1 {
    --fsSubtit: 15.5px;
    --fsTit: 48px;
    --fsDetail: 18px;
    --paddingDetail: 80px;
    --whiteSpace: pre-line;
    --lhDetail: 1.4;
    .section {
        height: 881px;
        width: 100%;
        position: relative;
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 0 25px;
        &-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 33%;
            transform: translateY(-50%);
            &-content {
                width: 50%;
                position: relative;
                .subtit {
                    font-size: var(--fsSubtit);
                    color: #0062f4;
                    font-family: $pretendardSemiBold;
                }
    
                .title {
                    padding: 10px 0;
                    width: 600px;
                    font-size: var(--fsTit);
                    font-family: $pretendardSemiBold;
                    color: $white-cl;
                    white-space: pre-line;
    
                    p {
                        line-height: 1.4;
                    }
                }
    
                .detail {
                    font-size: var(--fsDetail);
                    color: #eef6ff;
                    padding-bottom: var(--paddingDetail);
                    white-space: var(--whiteSpace);
    
                    p {
                        line-height: var(--lhDetail);
    
                        @media screen and (max-width: 767px) {
                            white-space: normal;
                            line-height: 1.4;
                        }
                    }
                }
    
                .button {


                  
                }
    
            
           
        }}

    }

    @media screen and (max-width: 1128px) {
        --fsTit: 35px;
         .section-container .detail p {
            line-height: 1.4;
            white-space: normal;
        }
        .section-container .title {
            line-height: 1.4;
            white-space: normal;
        }
    //     .section-img111 {
    //         margin: 0 auto;
    //         width: 100%;

    //     // img {
            
    //     //     width: 70%;
    //     //     padding-top: 10%;
    //     // }
    // }

    }

    @media screen and (max-width: 767px) {

        .section {
            width: 100%;
             height: 650px;
            --fsSubtit: 14.5px;
            --fsTit: 30px;
            // --fsDetail: 16px;
            // --paddingDetail: 30px;

            &-container {
                flex-direction: column-reverse;
                width: 90%;

                &-content {
                    width: 100%;
                    .subtit {
                        width: 100%;
                    }
                    .title {
                        padding-top: 20px;
                        width: 100%;
                    }
                    .detail {
                        display: none;
                    }                   
                }
             
            }

        }


    }

}