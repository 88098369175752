@import "../../../Assets/styles/index.scss";

.privacy {
  --fsTit: 2rem;
  --fsText: 16px;
  max-width: $maxWidth;
  margin: 150px auto;
  padding: 25px;
  font-family: Montserrat;
  color: rgb(0, 0, 0);

  &-title {
    font-size: var(--fsTit);
    font-family: $pretendardSemiBold;
  }

  article {
    margin-top: 12px;
    border: 1px solid #eceeef;
    padding: 1rem 1.5rem;
    line-height: 200%;
    font-size: var(--fsText);

    .content-detail {
      padding: 20px 0 10px;
    }

    .item {
      margin-top: 30px;

      .title {
        span {
          font-family: $pretendardExtraBold;
          padding-right: 5px;
        }
        color: rgb(33, 37, 41);
        font-family: $pretendardMedium;
        padding-bottom: 4px;
      }

      .item-content-tit {
        padding-top: 5px;
      }
    }

    .content-footer {
      margin-top: 50px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    th {
      border: 1px solid black;
    }

    td {
      padding: 10px;
      border: 1px solid black;
    }
  }

  @media screen and (max-width: 767px) {
    --fsTit: 1.2rem;
    --fsText: 14px;
    margin: 100px auto;
    padding: 20px;
  }
}
