@import "../../../../Assets/styles/variables";

.intro {
    background-color: #162a56;

    &-container {
        margin: 0 auto;
        max-width: $maxWidth;
        padding: 140px 25px;

        .intro-detail3 {
            margin-top: 80px;
            display: grid;
            grid-template-columns: 55% 45%;

            &-list {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 8px;
                row-gap: 20px;
                padding-left: 60px;

                &-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    visibility: hidden;

                    img {
                        max-width: 100px;
                        max-height: 100px;
                        object-fit: cover;
                    }

                    p {
                        white-space: pre-line;
                        text-align: center;
                        color: $white-cl;
                        font-size: 15px;
                        padding-top: 20px;
                        line-height: 1.3;
                        font-family: $pretendardSemiBold;
                    }
                }
            }
        }

        .intro-detail3-list-mobile {
            display: none;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 8px;
            row-gap: 20px;
            padding-top: 50px;
            align-items: center;
            justify-content: center;

            &-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    max-width: 100px;
                    max-height: 100px;
                    object-fit: cover;
                }

                p {
                    white-space: pre-line;
                    text-align: center;
                    color: $white-cl;
                    padding-top: 20px;
                    line-height: 1.3;
                    font-family: Pretendard_Variable;
                }
            }

        }
        .item-mobile {
            visibility: visible;
        }

        .intro-slide {
            padding-bottom: 50px;

            .slide-img {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

        }

        .intro-button {
            padding-top: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        //swiper
        .swiper-horizontal>.swiper-pagination-bullets,
        .swiper-pagination-bullets .swiper-pagination-horizontal,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {
            bottom: -3px
        }

        .swiper-pagination-bullet {
            width: 40px;
            height: 6px;
            background-color: $white-cl;
            border-radius: 3px;
        }

        .swiper-wrapper {
            padding-bottom: 40px;
        }

        @media screen and (max-width: 1400px) {
            .intro-detail3 {
                &-list {
                    padding-left: 40px;

                    &-item {
                        img {
                            max-width: 80px;
                            max-height: 80px;
                            object-fit: cover;
                        }

                        p {
                            font-size: 15px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1000px) {
            .intro-detail3 {
                grid-template-columns: 100%;
                row-gap: 40px;
                margin-top: 40px;

                &-list {
                    display: none;
                }
            }

            .intro-detail3-list-mobile {
                display: grid;
            }

        }

        @media screen and (max-width: 767px) {
            padding: 60px 20px;
            .intro-detail3 {
                &-list {

                    &-item {

                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
            .intro-detail3-list-mobile {
                grid-template-columns: 1fr 1fr;
                justify-content: center;
                align-items: center;
            }
        }
    }
}