.titleComponent {
    --fsTit: 40px;
    --fsDetail: 18px;
    --ptDetail: 30px;

    .title {
        font-size: var(--fsTit);
        font-family: Pretendard-SemiBold;
        text-align: center;

        span {
            font-family: Pretendard-SemiBold;
            font-size: var(--fsTit);
            color: #0062f4;
            padding: 0 4px;
            white-space: nowrap;
        }
    }

    .detail {
        padding-top: var(--ptDetail);
        font-size: var(--fsDetail);
        white-space: pre-line;
        text-align: center;
        line-height: 0.85;

        @media screen and (max-width: 1200px) {
            white-space: normal;
            line-height: 1.6;
        }
    }

    @media screen and (max-width: 767px) {
        --fsTit: 24px;
        --fsDetail: 15.5px;
        --ptDetail: 15px;
    }
}