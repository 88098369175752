@import "../../../../Assets/styles/index.scss";

.question {
    --width: 1140px;
    --padding: 140px 25px;
    --fsTit: 40px;
    --ptTabs: 40px;
    --plTab: 30px;
    --fsTab: 24px;
    --pbTabs: 90px;
    max-width: var(--width);
    padding: var(--padding);
    margin: 0 auto;

    &-title {
        font-size: var(--fsTit);
        font-family: $pretendardSemiBold;
        text-align: center;
        line-height: 1.3;
    }

    &-tabs {
        display: flex;
        justify-content: center;
        padding-top: var(--ptTabs);
        padding-bottom: var(--pbTabs);
        gap: 20px;
        .tab {
            font-size: var(--fsTab);
            color: #999;
            // padding-left: var(--plTab);
            cursor: pointer;
            display: inline-block;
            // cursor: none;
        }

        .activeTab {
            font-family: $pretendardSemiBold;
            color: #0062f4;
            text-decoration: underline;
            cursor: pointer;
        }

    }

    &-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
    }

    @media screen and (max-width: 767px) {
        --padding: 40px 20px;
        --fsTit: 30px;
        --ptTabs: 30px;
        --plTab: 12px;
        --fsTab: 16px;
        --pbTabs: 40px;
    }
}