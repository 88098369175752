@import "../../../../Assets//styles/index.scss";

.csService {
    --padding: 140px 25px;
    --ptList: 80px;
    --fsTit: 24px;
    --fsDetail: 16px;
    --grid: 1fr 1fr 1fr 1fr;
    --rowGap: 0px;
    margin: 0 auto;
    max-width: $maxWidth;
    padding: var(--padding);

    &-list {
        display: grid;
        grid-template-columns: var(--grid);
        padding-top: var(--ptList);
        column-gap: 25px;
        row-gap: var(--rowGap);

        .content {
            padding-top: 29px;

            .title11 {
                font-family: $pretendardSemiBold;
                font-size: 20px;
            }

            .detail11 {
                color: #999;
                font-size: 13px;
                padding-top: 19px;

                p {
                    line-height: 1.6;
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        --grid: 1fr 1fr;
        --rowGap: 30px
    }

    @media screen and (max-width: 767px) {
        --grid: 1fr;
        --rowGap: 30px
    }
}