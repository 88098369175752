@import "../../../../Assets/styles/variables";

.service {
    background-color: #F1F8FF;
    background-image: url(../../../../Assets/Images/02-bg@3x.png);
    background-size: contain;
    background-repeat: no-repeat;

    &-container {
        margin: 0 auto;
        max-width: $maxWidth;
        padding: 140px 25px;
        overflow: hidden;
        .service-list {
            display: grid;
            margin-top: 80px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 25px;
            visibility: hidden;

            .service-item-img {
                position: relative;
                cursor: pointer;
                transition:  400ms;
                &:hover {
                    transform: scale(1.05);
                }
                .content2 {
                    position: absolute;
                    left: 5%;
                    top: 50%;

                    &-title {
                        font-size: 20px;
                        font-family: $pretendardSemiBold;
                    }

                    &-detail {
                        font-size: 11px;
                        padding-top: 15px;
                        color: #999999;
                        line-height: 1.6;
                    }
                }
            }
        }

        .service-button {
            margin-top: 80px;
            display: flex;
            justify-content: center;
        }

        @media screen and (max-width: 1320px) {
            .service-list {
                grid-template-columns: 1fr 1fr;
                row-gap: 24px;
                visibility: visible;
            }
        }

        @media screen and (max-width: 768px) {
            padding: 60px 20px;

            .service-list {
                grid-template-columns: 1fr;
                row-gap: 24px;
                visibility: visible;
                .service-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                   &-img {
                    width: 80%;
                   }
                }
            }
        }

        @media screen and (max-width: 410px) {
            .service-list {
                .service-item {
                   &-img {
                    width: 90%;
                   }
                }
            }
        }

    }

}