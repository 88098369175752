@import "../../../Assets/styles/index.scss";

.banner2 {
    --fsSubtit: 20px;
    --fsTit: 48px;
    --fsDetail: 15px;
    --paddingDetail: 80px;
    --whiteSpace: pre-line;
    --lhDetail: 1.4;
    text-align: center;


    .section {
        height: 930px;
        width: 100%;
        position: relative;
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 0 0px;
        &-container {
            // display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 55%;
            transform: translateY(-50%);
            &-content {
                width: 100%;
                .subtit {
                    font-size: var(--fsSubtit);
                    color: #0062f4;
                    font-family: $pretendardSemiBold;
                    // text-align: left;
                }
    
                .title {
                    padding: 5px 0;
                    font-size: var(--fsTit);
                    font-family: $pretendardSemiBold;
                    color: $white-cl;
                    white-space: pre-line;
                    // text-align: left;
    
                    p {
                        line-height: 1.4;
                    }
                }
    
                .detail {
                    font-size: var(--fsDetail);
                    color: #eef6ff;
                    // padding-bottom: var(--paddingDetail);
                    white-space: var(--whiteSpace);
    
                    p {
                        line-height: var(--lhDetail);
    
                        @media screen and (max-width: 767px) {
                            white-space: normal;
                            line-height: 1.4;
                        }
                    }
                }
                
                
               
            

            }
            .button_main {
                width: 600px;
                display: flex;
                margin: 0 auto;
            }
            .button {
                width: 300px;
                // margin: 0 auto;
            }
            .button2 {
                width: 300px;
                // margin: 0 auto;

            }
            .section-img1111 {
                width: 100%;
                // padding-left: 20px;
                margin-bottom: 0%;

                
            }
            .section-img1111 img {
                width: 100%;
                margin: 0 auto;
       
            }
         
        }

    }

    @media screen and (max-width: 1128px) {
        --fsTit: 35px;
         .section-container .detail p {
            line-height: 1.4;
            white-space: normal;
        }
        .section-container .title {
            line-height: 1.4;
            white-space: normal;
        }
             
    }

    @media screen and (max-width: 767px) {

        .section {
             height: 740px;
            --fsSubtit: 14.5px;
            --fsTit: 30px;
            --fsDetail: 16px;
            --paddingDetail: 30px;

            &-container {
                flex-direction: column-reverse;
                &-content {
                    
                    width: 100%;
                    padding-right: 20px;
                }
                .section-img1111 {
                    width: 100%;
                    margin: 10% auto;
                }



                .button_main {
                    width: 450px;
                    display:inline;
                    margin: 20px auto 0;
                }
                .button {
                    width: 250px;
                    height: 0px;
                    margin: 0 auto;
                    // display:inline;
                }
                .button2 {
                    width: 250px;
                    height: 100px;
                    margin: 60px auto 0;
    
                }
            }
        }

    }

}