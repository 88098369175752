@import "../../../../Assets/styles/index.scss";

.reason3 {
    --padding: 140px 25px;
    --ptList: 64px;
    // --fsTit: 24px;
    // --fsDetail: 16px;
    --sizeImg: 100px;
    --paddingTitle: 30px 0 20px;
    --ptButton: 100px;
    --grid: 1fr 1fr 1fr 1fr 1fr;
    --rowGap: 0px;
    background-color: #f1f8ff;

    &-container {
        max-width: $maxWidth;
        padding: var(--padding);
        margin: 0 auto;

        &-list {
            display: grid;
            grid-template-columns: var(--grid);
            padding-top: var(--ptList);
            column-gap: 25px;
            row-gap: var(--rowGap);

            .item {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    width: var(--sizeImg);
                    height: var(--sizeImg);
                    object-fit: contain;
                }

                .title5 {
                    font-size: var(--fsTit);
                    font-family: $pretendardSemiBold;
                    padding: var(--paddingTitle);
                    white-space: pre-line;
                }

                .detail5 {
                    font-size:15px;
                    line-height: 1.4;
                    color: #999;
                    white-space: pre-line;

                }
            }
        }

        &-button {
            padding-top: var(--ptButton);
            display: flex;
            justify-content: center;
        }
    }

    @media screen and (max-width: 1400px) {
        --fsTit: 20px;
        --fsDetail: 14px;
    }

    @media screen and (max-width: 1023px) {
        --grid: 1fr 1fr;
        --rowGap: 30px;
    }

    @media screen and (max-width: 767px) {
        --grid: 1fr;
        --rowGap: 30px;
        --padding: 40px 20px;
        --ptList: 64px;
        --sizeImg: 70px;
        --paddingTitle: 20px 0 20px;
        --ptButton: 50px;
    }
}