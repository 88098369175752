@import "../../../../Assets//styles/index.scss";

.FREEservice {
    --padding: 100px 25px;
    --ptList: 80px;
    --fsTit: 24px;
    --fsDetail: 14px;
    --mtList: 80px;
    --paddingItem: 5px 25px;
    --sizeImg: 60px;
    --grid: 0.8fr 0.8fr 0.8fr 0.8fr;
    --rowGap: 0;
    background-color: #63aeff;

    &-container {
        margin: 0 auto;
        max-width: $maxWidth;
        padding: var(--padding);

        &-list {
            display: grid;
            grid-template-columns: var(--grid);
            margin-top: var(--mtList);
            column-gap: 24px;
            row-gap: var(--rowGap);
            margin-bottom: 80px;

            .item {
                background-color: #fff;
                border-radius: 17px;
                padding: var(--paddingItem);
                margin: 0px 0 30px;

                img {
                    width: 50px;
                    height: var(--sizeImg);
                    object-fit: contain;
                    margin: 10px auto 0;
                }

                .title {

                    font-size: var(--fsTit);
                    font-family: $pretendardSemiBold;
                    padding: 5px 0 20px;
                    text-align:center;
                }
                .detail {
                    white-space: pre-line;
                    font-size: var(--fsDetail);
                    margin: 20px 0 10px;
                    line-height: 1.4;
                    color: #999;
                }
            }
        }
    }
    .FREEVversion22 {
        margin: 60px auto 0;
        border-radius: 20px;
        border-right: 10px solid #fff;
        border-left: 10px solid #fff;
        width: 75%;
        height: 200px;
        // background-color: #fff;
    }
    .imgMM{
        width: 200px;
        height: 200px;
        margin: 0 0 0 13%;
        float: left;
        background-color: #fff;
        border-radius: 50%;
    }
    .imgMM img{
width: 150px;
       margin: 25px auto;


    }
    .basic_M ul{

       padding: 3% 0 0 45%;
      text-align: center;
        list-style: none;
        line-height: 1.5;
    }

    .BASIC{
        font-size: 30px;
        margin-top: 5px;
    }
    .BASIC22{
        font-size: 15px;
        margin: 5px 0 10px;
    }    
    .BASIC33{
        color: #fff;
        font-size: 15px;
    }



    @media screen and (max-width: 1300px) {
        --grid: 1fr 1fr;
        --rowGap: 30px;
       
        .FREEVversion22 {
            margin: 60px auto 0;
            border-radius: 20px;
            border-right: 10px solid #fff;
            border-left: 10px solid #fff;
            width: 90%;
            height: 200px;
            // background-color: #fff;
        }
    }

    @media screen and (max-width: 767px) {
        --grid: 1fr;
        --padding: 40px 20px;
        --ptList: 40px;
        --fsTit: 20px;
        --fsDetail: 14px;
        --paddingItem: 15px;

        .FREEVversion22 {
            margin: 60px auto 0;
            border-radius: 20px;
            border-right: 10px solid #fff;
            border-left: 10px solid #fff;
            width: 100%;
            height: 200px;
            // background-color: #fff;
        }
        .BASIC{
            font-size: 30px;
            margin-top: 15px;
        }


        .imgMM{
            width: 150px;
            height: 150px;
            margin: 25px 0 0 8%;
            float: left;
            background-color: #fff;
            border-radius: 50%;
        }
        .imgMM img{
    width: 100px;
           margin: 25px auto;
    
    
        }


        .BASIC22{
            font-size: 12px;
            margin: 5px 0 10px;
        }
        
    }

    @media screen and (max-width: 400px) {
        --grid: 1fr;
        --padding: 40px 20px;
        --ptList: 40px;
        --fsTit: 20px;
        --fsDetail: 14px;
        --paddingItem: 15px;

        .FREEVversion22 {
            margin: 60px auto 0;
            border-radius: 20px;
            border-right: 10px solid #fff;
            border-left: 10px solid #fff;
            width: 100%;
            height: 200px;
            // background-color: #fff;
        }
        .BASIC{
            font-size: 30px;
            margin-top: 20px;
        }
        .imgMM{
            width: 1000px;
            margin: 25px 0 0 8%;
            float: left;
        }

        .imgMM{
            width: 100px;
            height: 100px;
            margin: 45px 0 0 8%;
            float: left;
            background-color: #fff;
            border-radius: 50%;
        }
        .imgMM img{
    width: 75px;
           margin: 12.5px auto;
    
    
        }


        .BASIC22{
            font-size: 12px;
            margin: 5px 0 10px;
        }
        .BASIC33{
            font-size: 12px;
        }
        
    }



}