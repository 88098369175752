@font-face {
  font-family: "regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

.more_wrap {
  * {
    font-family: "regular";
  }
}

button {
  text-align: center;
  background-color: white;
  border: none;
}

.addup_btn {
  display: block;
  width: 100%;
  color: white;
  line-height: 46px;
  background-color: #1c274c;
  padding: 0 16px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.login-bg {
  height: 100vh;
  width: 100vw;
  position: relative;
  background-image: url(../public/imgs/bg.png);
}

.p-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.desc {
  font-size: 14px;
  color: #969696;
}

.text-primary {
  font-weight: bold;
  font-size: 14px;
  color: #1c274c;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.item-center {
  align-items: center;
}

.w-full {
  width: 100%;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.modal {
  width: 800px;
  height: 500px;
  background-color: white;
  overflow: auto;
  padding: 20px;
  border-radius: 12px;
}

.bg {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
}
