@import "../../../../Assets/styles/variables";
.server {
  --padding: 140px 25px;
  --grid: 1fr 1fr 1fr 1fr 1fr;
  --gridTop: 64px;
  --paddingItem: 48px 30px;
  --sizeImg: 79px;
  --fsDetail: 16px;

  &-container {
    margin: 0 auto;
    max-width: $maxWidth;
    padding: var(--padding);
    .server-list {
      display: grid;
      grid-template-columns: var(--grid);
      column-gap: 24px;
      row-gap: 24px;
      margin-top: var(--gridTop);
      &-item {
        padding: var(--paddingItem);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        visibility: hidden;
        cursor: pointer;
        p {
          padding-top: 30px;
          white-space: pre-line;
          font-family: $pretendardSemiBold;
          text-align: center;
          font-size: var(--fsDetail);
          @media screen and (max-width: 767px) {
            white-space: normal;
          }
        }
        img {
          max-width: var(--sizeImg);
          max-height: var(--sizeImg);
          object-fit: cover;
        }
        &:hover {
          transition: 400ms !important;
          transform: scale(1.2) !important;
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    --grid: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 767px) {
    --grid: 1fr 1fr;
    --padding: 40px 20px;
    --gridTop: 34px;
    --paddingItem: 18px 20px;
    --sizeImg: 50px;
    --fsDetail: 12px;
  }
}
