@import "../../../../Assets/styles/index.scss";

.priceBanner {
    --padding: 100px 0px 116px;
    --fsTit: 48px;
    --mtList: 80px;
    --fsType: 24px;
    --sizeImg: 81px;
    --fsSubTit: 15.5px;
    --fsTitItem: 32px;
    --fsContentItem: 14.5px;
    --grid: repeat(5,1fr);
    --rowGap: 0px;
    --topContent: 17px;
    --mtText: 40px;
    --paddingSubtit: 10px 0;
    --paddingTitle: 10px 0 15px;
    --bottomButton: 18px;
    --fsContentDetail: 18px;
    background-color: #62B0FF;

    &-container {
        max-width: $maxWidth;
        padding: var(--padding);
        margin: 0 auto;

        &-content {
            display: flex;
            justify-content: center;
            align-items: center;

            .img {
                width: 40%;

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

            .content {
                flex: 60%;

                p {
                    font-size: var(--fsTit);
                    color: #fff;
                }

                .bold {
                    color: #bbdbff;
                }

                .detail {
                    font-size: var(--fsContentDetail);
                    line-height: 1.7;
                    padding-top: 30px;
                    color: #eef6ff;
                }
            }
        }

        .priceTypes {
            display: grid;
            grid-template-columns: var(--grid);
            padding-top: 82px;
            column-gap: 18px;
            row-gap: 30px;

            .priceType {
                &-container {

                    background-color: #fff;
                    border-radius: 17px;
                    flex: 0 0 auto;

                    .type {
                        background-color: #b5b5b5;
                        padding: 12px;
                        width: 104%;
                        transform: translate(-2%, 30%);
                        text-align: center;

                        span {
                            color: #fff;
                            font-size: 19px;
                            font-family: $pretendardSemiBold;
                        }
                    }

                    .content {
                        padding: 30px 5px 20px;
                        text-align: center;

                        .quantity,
                        .sheet {
                            font-size: 12.5px;

                        }

                        .img {
                            width: 45px;
                            height: 45px;
                            margin: 10px auto;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }

                        .title {
                            font-size: 25.5px;
                            font-family: $pretendardSemiBold;
                            padding: 12px 0;

                        }

                        .feature {
                            font-size: 12px;

                            span {
                                color: #0062f9;
                                font-family: $pretendardSemiBold;
                            }
                        }

                        .detail {
                            padding: 12px 0;
                            justify-content: center;
                            min-height: 65px;

                            .detail-item {
                                display: flex;
                                align-items: center;
                                padding-top: 5px;
                                padding-left: 8px;
                                flex-wrap: nowrap;
                                img {
                                    width: 18px;
                                    height: 18px;
                                    object-fit: contain;
                                }

                                &-content {
                                    font-size: 13px;
                                    color: #999999;
                                    letter-spacing: -0.3px;
                                    padding-left: 2px;
                                    span {
                                        color: #222222;
                                    }
                                }
                            }
                        }

                    }

                    .button {
                        padding: 19px 2px;
                        background-color: #f1f8ff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-bottom-left-radius: 17px;
                        border-bottom-right-radius: 17px;

                        .button-price {
                            padding-left: 37px;
                            padding-right: 37px;
                        }
                    }
                }
            }
        }

    }

    @media screen and (max-width: 1400px) {
        --rowGap: 30px;
        --paddingSubtit: 25px 0;
        --paddingTitle: 18px 0 25px;
        --bottomButton: 25px;

        &-container {
            .item-img {
                img {
                    height: 600px;
                    margin: 0 auto;
                }
            }
        }
    }

    @media screen and (max-width: 1220px) {
        --padding: 200px 25px 116px;
        --grid: repeat(3,1fr)
    }

    @media screen and (max-width: 900px) {
        --fsTit: 35px;
        --padding: 123px 25px 86px;
    }

    @media screen and (max-width: 767px) {
        --fsTitItem: 20px;
        --grid: 1fr 1fr;
        --fsTit: 24px;
        --mtList: 20px;
        --fsType: 18px;
        --sizeImg: 51px;
        --fsSubTit: 14.5px;
        --fsContentItem: 12px;
        --mtText: 20px;
        --padding: 100px 20px 36px;
        --paddingSubtit: 25px 0;
        --paddingTitle: 10px 0 20px;

        &-container {
            padding: var(--padding);
            margin: 0 auto;

            &-content {
                flex-direction: column;
                text-align: center;

                .img {
                    width: 100%;
                    margin-top: 30px;
                }
                .detail {
                    font-size: 14px;
                    br {
                        display: none;
                    }
                }
            }
       
        }
    }

    @media screen and (max-width: 500px) {
        --grid: 1fr;
        &-container {
            .priceTypes {
                width: 70%;
                margin: 0 auto
            }
        }
    }

    @media screen and (max-width: 380px) {
        &-container {
            .priceTypes {
                width: 80%;
                margin: 0 auto
            }
        }
    }

    @media screen and (max-width: 320px) {
        &-container {
            .priceTypes {
                width: 90%;
                margin: 0 auto
            }
        }
    }
}