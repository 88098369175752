// Montserrat
@import "./variables.scss";
@import "./mixins";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-tab-highlight-color: transparent;
  user-select: none;
  // @include family--montserrat;
}

body {
  font-family: $pretendardVariable;
  overflow-x: hidden;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

video {
  max-width: 100%;
}

img {
  max-width: 100%;
}
