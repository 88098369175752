@import "../../../../Assets/styles/index.scss";

.memberMap {
    --height: 140px;
    --heightImg: 600px;
    --sizeImgItem: 70px;
    --fsTit: 22px;
    --fsDetail: 16px;
    --plImg: 25px;
    --grid: 1fr 1fr 1fr;
    position: relative;
    height: var(--height);
    background-color: #316ba8;
    // color: #fff;

    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #cfe2fd;
    }

    &-container {
        position: absolute;
        max-width: $maxWidth;
        transform: translate(-50%, -50%);
        top: 35%;
        left: 50%;
        z-index: 1;
        width: 100%;
        padding: 0 20px;


        .map {
            margin: 10px 0 10px;

            img {
                // max-height: 50%;
                object-fit: contain;
                margin: 0 auto;
            }
        }

        .map-list {
            display: grid;
            grid-template-columns: var(--grid);

            .item {
                display: flex;
                align-items: center;

                .img {
                    img {
                        width:100%;
                        height: var(--sizeImgItem);
                        object-fit: contain;
                        margin: 0 auto;
                    }
                }

                .content {
                    text-align: center;
                    width: 380px;
                    .title {
                        width: 315px;
                        font-size: var(--fsTit);
                        font-family: $pretendardSemiBold;
                        color: #ffffff;
                        margin: 0 auto;
                        padding:  10px 10px 0;
                    }

                    .detail {
                        font-size: var(--fsDetail);
                        padding-top: 10px;
                        color: #ccc;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1500px) {
        // --maxWidth: 90%;
    }

    @media screen and (max-width: 1200px) {
        --sizeImgItem: 70px;
        --fsTit: 22px;
        --fsDetail: 15px;
        --plImg: 15px;
        // --height: 500px;
        .map{

            width: 100%;
        }
    }

    @media screen and (max-width: 767px) {
        --sizeImgItem: 70px;
        --fsTit: 20px;
        --fsDetail: 17px;
        --plImg: 0px;
        --grid: 1fr;
        --height: 600px;

        .map{
            img{
                width: 95%;
            }
            padding-top: 20%;
        }
        .map-list {
            .item {
                justify-content: center;
                padding-top: 40px;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }
        .content {
        
            .title {
                padding-top: 10%;
            }
            .detail {
               
            }
        }
   
    }
}