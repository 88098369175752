@import "../../../Assets/styles/index.scss";
#companyJoin {
  .domian_login_contents {
    width: 460px;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;

    .logo {
      width: 150px;
      margin: 0 auto;
    }
    p {
      font-size: 18px;
      font-weight: bold;
    }

    .mini {
      width: auto;
      line-height: 24px;
    }
  }
}
