@import "../../../../Assets/styles/index.scss";

.instruction {
    --padding: 140px 25px;
    --ptImg: 80px;
    --fsTit: 40px;
    --fsDetail: 16px;
    --widthImg: 586px;
    --heightImg: 400px;

    &-container {

        &-item {
            margin: 0 auto;
            max-width: $maxWidth;
            padding: var(--padding);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .content {
                width: 40%;

                .title1 {
                    font-size: 35px;
                    font-family: $pretendardSemiBold;
                    white-space: pre-line;
                }

                .detail1 {
                    padding-top: 30px;
                    font-size: 14.5px;
                    color: #999;
                    line-height: 2;
                    white-space: pre-line;
                }
            }

            .img {
                flex: 1;
                padding-left: 10px;
                display: flex;

                img {
                    object-fit: contain;
                    width: var(--widthImg);
                    height: var(--heightImg);
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        --widthImg: 396px;
        --heightImg: 320px;
    }

    @media screen and (max-width: 767px) {
        --padding: 40px 20px;
        --fsTit: 22px;
        --fsDetail: 14px;
        --widthImg: 85%;
        --heightImg: 220px;

        &-container {

            &-item {
                padding: var(--padding);
                flex-direction: column !important;

                .content {
                    width: 100%;
                    text-align: center;

                    .detail {
                        white-space: normal;
                    }
                }

                .img {
                    display: flex;
                    justify-content: center !important;
                    padding-left: 0;
                }
            }
        }
    }
}