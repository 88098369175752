@import "../../../Assets/styles/index.scss";



    .FREEMain{
    margin: 0 auto;
width: 1200px;

        max-height: var(--heightImg);
        object-fit: contain;
    
}
.img11{  
    top: 195px;
    margin: 0 auto;
    position: absolute;
}
.img22{

    top: 718px;
    margin: 0 auto;
    position: absolute;
}

@media screen and (max-width: 1200px) {
    .img11{  
        top: 280px;
        margin: 0 auto;
        position: absolute;
    }
    .img22{
    
        top: 745px;
        margin: 0 auto;
        position: absolute;
    }
}

@media screen and (max-width: 1000px) {
    .img11{  
        top: 320px;
        margin: 0 auto;
        position: absolute;
    }
    .img22{
    display: none;
        top: 680px;
        margin: 0 auto;
        position: absolute;
    }

}
