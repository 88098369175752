@import "../../../Assets/styles/index.scss";
#join {
  .domian_login_contents {
    width: 460px;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;

    .logo {
      width: 150px;
      margin: 0 auto;
    }
    p {
      font-size: 18px;
      font-weight: bold;
    }

    .icon {
      width: 24px;
      height: 24px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
