@import "../../../../../Assets/styles/index.scss";

.accordion {
  border-bottom: 1px solid #e8e8e8;

  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: 20px 40px;
    font-size: 18px;
    line-height: 1.4;
    font-family: $pretendardSemiBold;
    align-items: center;
    .accordion-img {
      width: 10%;
      display: flex;
      justify-content: flex-end;
    }
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }

    @media screen and (max-width:767px) {
      padding: 30px 0;
      font-size: 16px;
      
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .accordion-title-active {
    background-color: #fafafa;
    border-top: 1px solid #222;
    border-bottom: 1px solid #e8e8e8;
  }


  .accordion-content {
    padding: 20px 60px;
    font-size: 16px;
    color: #999999;
    background-color: #fafafa;
    white-space: pre-line;
    line-height: 1.4;

    @media screen and (max-width:767px) {
      white-space: normal;
      line-height: 1.4;
      padding: 20px 9px;
    }
  }
}