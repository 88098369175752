@import "../../../../Assets/styles/index.scss";

.reason {
    --padding: 140px 25px;
    --ptList: 64px;
    --fsTit: 24px;
    --fsDetail: 14px;
    --sizeImg: 100px;
    --paddingTitle: 30px 0 20px;
    --ptButton: 100px;
    --grid: 1fr 1fr 1fr 2fr 1fr;
    --rowGap: 0px;
    background-color: #cfe2fd;

    &-container {
        max-width: $maxWidth;
        padding: var(--padding);
        margin: 0 auto;

        &-list {
            display: grid;
            grid-template-columns: var(--grid);
            padding-top: var(--ptList);
            column-gap: 10px;
            row-gap: var(--rowGap);

            .reason2-container-list{
                background-color: #fff;

                
            }
            .item {
                width: 210px;
                background-color: #fff;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 15px;

                img {
                    margin-top: 25px;
                    width: var(--sizeImg);
                    height: var(--sizeImg);
                    object-fit: contain;
                }

                .title {
                    font-size: var(--fsTit);
                    font-family: $pretendardSemiBold;
                    padding: var(--paddingTitle);
                    white-space: pre-line;
                }

                .detail {
                    width: 200px;
                    font-size: var(--fsDetail);
                    line-height: 1.4;
                    color: #999;
                    white-space: pre-line;
                    padding: 5px 0 15px;
               

                }
            }
        }

        &-button {
            padding-top: var(--ptButton);
            display: flex;
            justify-content: center;
        }
    }

    @media screen and (max-width: 1400px) {
        --fsTit: 20px;
        --fsDetail: 14px;
    }

    @media screen and (max-width: 1023px) {
        --grid: 1fr 1fr;
        --rowGap: 30px;
    }

    @media screen and (max-width: 767px) {
        --grid: 1fr;
        --rowGap: 20px;
        --padding: 40px 30px 30px;
        --ptList: 30px;
        --sizeImg: 70px;
        --paddingTitle: 5px 0 10px;
        --ptButton: 50px; 


        .reason {

        
            &-container {
    
        
                &-list {

        
                    .item {
                        margin: 0 auto;
                        width: 300px;
        
                        img {
              
                        }
        
                        .title {
                 font-size: 22px;
                        }
        
                        .detail {
                            font-size: 16px;
                            width: 250px;
                       
        
                        }
                    }
                }
            }










        }
    
        // .detail2 {
        //     font-size: var(--fsDetail);
        //     line-height: 1.4;
        //     color: #999;
        //     white-space: nowrap;

        // }
        // .reason-container-list2 li{
        //     width: 120px;
        //     margin: 0 auto;
        // }
        // .reason-container-list2 li .title2{
        //     font-size: 15px;
        // }  
        // .reason-container-list2 li .detail2{
        //     font-size: 11px;
        //     width: 150px;
            
        //     white-space: pre-wrap;
        // }
    }
}