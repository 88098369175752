@import "../../../../Assets/styles/index.scss";

.priceTable {
    --padding: 120px 25px;
    --paddingTitTable: 20px 8px;
    --fsTitTable: 18px;
    --ptTableItem: 105px;
    --pbTitTable: 22px;
    --sizeImgTable: 32px;
    --fsItemTableTitle: 32px;
    --paddingItemTable: 22px 0;
    --fsSubDetailTable: 16px;
    --grid: 40% auto;
    --sizeImg: 26px;

    &-container {
        max-width: $maxWidth;
        margin: 0 auto;
        padding: var(--padding);
.main-title{
    font-size: 24px;
}
        .title-table {
            background-color: #fafafa;
            padding: var(--paddingTitTable);
            display: grid;
            grid-template-columns: var(--grid);
            column-gap: 20px;
            font-size: var(--fsTitTable);
            font-family: $pretendardSemiBold;

            .detail {
                display: flex;
                align-items: center;

                &-item {
                    width: calc(100% / 4);
                    text-align: center;
                }

                .free {
                    color: #b8daff;
                }

                .basic {
                    color: #90c4fd;
                }

                .premium {
                    color: #228cff;
                }

                .special {
                    color: #0062f9;
                }
            }
        }

        .fixed-menu {
            position: sticky;
            right: 0;
            max-width: $maxWidth;
            width: 100%;
        }

        .list-table {
            .table-item {
                padding-top: var(--ptTableItem);

                &-title {
                    display: flex;
                    padding-bottom: var(--pbTitTable);
                    align-items: center;
                    border-bottom: 1px solid #e8e8e8;

                    img {
                        width: var(--sizeImgTable);
                        height: var(--sizeImgTable);
                        object-fit: contain;
                    }

                    span {
                        
                        font-size: var(--fsItemTableTitle);
                        font-family: $pretendardSemiBold;
                        padding-left: 8px;
                    }
                }

                &-content {
                    
                    border-bottom: 1px solid #e8e8e8;

                    .item {
                        padding: var(--paddingItemTable);
                        display: grid;
                        grid-template-columns: var(--grid);
                        column-gap: 20px;
                        font-size: var(--fsTitTable);
                        font-family: $pretendardSemiBold;
                        .title{
                            padding-left: 38px;
                        }
                        .detail {
                            display: flex;
                            align-items: center;

                            &-child {
                                width: calc(100% / 4);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img {
                                    width: var(--sizeImg);
                                    height: var(--sizeImg);
                                    object-fit: contain;
                                }

                                span {
                                    
                                    color: #e8e8e8;
                                }
                            }
                        }

                        .subdetail {
                            font-size: var(--fsSubDetailTable);
                            color: #999;
                            padding-top: 6px;
                        }
                    }
                }
            }
        }

        .table-note {
            padding-top: 20px;
            font-size: var(--fsSubDetailTable);
            color: #999;
        }
    }

    @media screen and (max-width: 1000px) {
        --padding: 80px 25px;
        --paddingTitTable: 20px 32px;
        --fsTitTable: 20px;
        --ptTableItem: 75px;
        --pbTitTable: 22px;
        --sizeImgTable: 26px;
        --fsItemTableTitle: 24px;
        --paddingItemTable: 22px 0;
        --fsSubDetailTable: 16px;
        --grid: 30% auto;
    }

    @media screen and (max-width: 767px) {
        --padding: 80px 10px;
        --paddingTitTable: 20px 2px;
        --fsTitTable: 16px;
        --ptTableItem: 25px;
        --pbTitTable: 22px;
        --sizeImgTable: 20px;
        --fsItemTableTitle: 20;
        --paddingItemTable: 22px 0;
        --fsSubDetailTable: 12px;
        --grid: 16% auto;
        --sizeImg: 25px;


        .title {
            font-size: 10px;

        }
        .priceTable-container .list-table .table-item-content .item .title{
            padding-left: 4px;
        }

        .detail-item {
            font-size: 10px;
        }

        .list-table .table-item-content .item .detail-child {
            font-size: 10px;
        }
    }
}