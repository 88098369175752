@import "../../../../Assets/styles/index.scss";

.qaService {
    --padding: 140px 25px;
    --height: 1039px;
    --pt: 100px;
    --grid: 1fr 1fr;
    --fsTit: 32px;
    --fsDetail: 16px;
    --distance: 38px;
    background-image: url(../../../../Assets/Images/bg-whatis-qaservice@3x.png);
    width: 100%;
    height: var(--height);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #f1f8ff;

    &-container {
        margin: 0 auto;
        max-width: $maxWidth;
        padding: var(--padding);

        &-list {
            padding-top: var(--pt);
            display: grid;
            grid-template-columns: var(--grid);
            column-gap: 24px;
            row-gap: 37px;

            .item {
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .item-content {
                    color: #fff;
                    position: absolute;
                    top: var(--distance);
                    left: var(--distance);
                    right: var(--distance);

                    .title {
                        font-family: $pretendardSemiBold;
                        font-size: var(--fsTit);
                    }

                    .detail {
                        white-space: pre-line;
                        padding-top: 21px;
                        line-height: 1.6;
                        font-size: var(--fsDetail);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1500px) {
        background-size: cover;
    }

    @media screen and (max-width: 1023px) {
        --grid: 1fr;
        height: 100%;
    }

    @media screen and (max-width: 767px) {
        --padding: 40px 20px;
        --fsTit: 18px;
        --fsDetail: 12.5px;
        --distance: 30px;
        --pt: 60px;

        .item {
            height: 320px;

            img {
                object-fit: contain;
            }
        }
    }

    @media screen and (max-width: 680px) {
        .item {
            height: 270px;
        }
    }

    @media screen and (max-width: 539px) {
        .item {
            height: 200px;

            .item-content {
                top: 20px;
                left: 40px;
            }

        }
    }

    @media screen and (max-width: 439px) {
        --fsTit: 16px;
        --fsDetail: 12.5px;

        &-container-list {
            row-gap: 20px;
        }

        .item {
            height: 160px;

            .item-content {
                left: 30px;

                .detail {
                    white-space: normal;
                    padding-top: 12px;
                    line-height: 1.4;
                }
            }

        }
    }

    @media screen and (max-width: 350px) {
        --fsTit: 14px;
        --fsDetail: 11.5px;

        .item {
            height: 160px;

            .item-content {
                top: 30px;
                left: 25px;
            }

        }
    }


}